.footer {
  background: #1d1d1d;
}

.footer__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 1rem;
  flex-wrap: wrap;
  color: white;
  justify-content: center;

  li {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

}

.footer__copyright {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  color: white;
  justify-content: center;
  align-items: center;

  grid-column-end: 3;
  grid-column-start: 1;
  padding: 0;
  gap: 1rem;
}

.footer__link {
  color: var(--main-color);
  font-size: 1.5rem;
}


.footer__text {
  color: white;
  font-weight: 400;
  opacity: 0.4;
}

.footer__row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem;
  gap: 2rem;
}

.footer__logo {
  display: block;
  width: 20rem;
  max-width: 100%;

}

.footer__logo img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .footer__row {
  grid-template-columns: 1fr;

  }

  .footer__list {
    align-items: center;
     li {
       align-items: center;
       justify-content: center;
     }
  }

  .footer__link {
    text-align: center;
  }

  .footer__copyright {
      grid-column-end: 2;
    text-align: center;
  grid-column-start: 1;
  }
}